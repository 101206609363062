.library {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 2px 2px 50px #808080;
  overflow: scroll;
  transform: translateX(-120%);
  transition: all 0.5s ease;

  h2 {
    padding: 2rem;
    box-shadow: 0px -30px 100px #808080;
  }

  .library-song {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    cursor: pointer;
    transition: all 0.5s ease;

    img {
      width: 30%;
    }

    &:hover {
      background-color: #DEDEFF;
    }

    .song-description {
      padding-left: 1rem;

      h3 {
        font-size: 1rem;
        text-transform: capitalize;
      }

      h4 {
        font-size: 0.8rem;
        text-transform: capitalize;
      }
    }
  }
}

.active {
  background-color: #DEDEFF;
}

.active-library {
  transform: translateX(0%);
  opacity: 1;
}

@media screen and (max-width: 768px) {
  .library {
    width: 70%;
  }
}