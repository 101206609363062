@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(155, 155, 155, 0.5) transparent;
}

*::-webkit-scrollbar {
  width: 5px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: rgba(155, 155, 155, 0.5);
  border-radius: 20px;
  border: transparent;
}

body {
  font-family: 'Roboto', sans-serif;
  overflow: hidden;
}

h1,
h2,
h3 {
  color: rgb(54, 54, 54);
}

h3,
h4 {
  font-weight: 400;
  color: #808080;
}

.player-wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  margin-top: -35px;
}

.player {
  width: 100%;
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.App{
  transition: all 0.5s ease;
}

.library-active{
  margin-left: 16%;
}
@import "./player";
@import "./song";
@import "./library";
@import "./nav";