.player-container {
  min-height: 20vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .time-control {
    width: 70%;
    display: flex;

    input {
      width: 100%;
      -webkit-appearance: none;
      background: transparent;
      cursor: pointer;
    }

    input[type="range"]:focus {
      outline: none;
    }

    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      height: 16px;
      width: 16px;
    }

    input[type="range"]::-moz-range-thumb {
      -webkit-appearance: none;
      background: none;
      border: none;
    }

    p {
      margin: 1rem;
    }

    .track {
      width: 100%;
      height: 1rem;
      background-color: lightblue;
      margin: 1rem 0;
      position: relative;
      border-radius: 1rem;
      overflow: hidden;
    }

    .animate-track {
      background: rgb(204, 204, 204);
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      transform: translateX(0%);
      pointer-events: none;
    }
  }

  .play-control {
    width: 30%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;

    svg {
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {
  .player-container {
    .time-control {
      width: 90%;
    }

    .play-control {
      width: 50%;
    }
  }
}