.song-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 20%;
    border-radius: 50%;
    box-shadow: 2px 2px 50px #696868;
  }

  h2 {
    padding: 3rem 1rem 1rem 1rem;
    text-transform: capitalize;
  }

  h3 {
    font-size: 1rem;
    text-transform: capitalize;
  }
}

.rotateImage {
  animation: rotateImage 15s linear forwards infinite;
}

@keyframes rotateImage {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
  .song-container {
    img {
      width: 50%;
    }
  }
}